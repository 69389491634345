import request from '@/utils/http'
import { awaitWrap } from '@/utils/util'

/**
 * 用户列表
 * @param {*} "pageNo" 页码
 * @param {*} "pageSize" 页数
 * @param {*} "id" 搜索id
 * @param {*} "keyWords" 搜索关键字
 * @param {*} "startTime" 开始时间
 * @param {*} "endTime" 结束时间
 * @returns
 */
export const userList = ({ ...params }) => {
  return awaitWrap(
    request({
      url: `/user/list`,
      method: 'get',
      params: params,
      isLoading: true
    })
  )
}
/**
 * 金牌推荐官列表
 * @param {*} "pageNo" 页码
 * @param {*} "pageSize" 页数
 * @param {*} "keyWords" 搜索关键字
 * @param {*} "isPhoneAuth" 
 * @param {*} "isRealNameAuth" 
 * @param {*} "startTime" 开始时间
 * @param {*} "endTime" 结束时间
 * @returns
 */
export const userGoldenReferList = ({ ...params }) => {
  return awaitWrap(
    request({
      url: `/user/goldenRefer/list`,
      method: 'get',
      params,
      isLoading: true
    })
  )
}
/**
 * 取消金牌代理资格
 * @param {*} "userId" 页码
 * @returns
 */
export const userGoldenCancel = ({ ...data }) => {
  return awaitWrap(
    request({
      url: `/user/golden/cancel`,
      method: 'post',
      data,
      isLoading: true
    })
  )
}

/**
 * 获取官方列表
 * @param {*} "pageNo" 页码
 * @param {*} "pageSize" 页数
 * @param {*} "keyWords" 搜索关键字
 * @param {*} "startTime" 开始时间
 * @param {*} "endTime" 结束时间
 * @returns
 */
export const userOfficialList = ({ ...params }) => {
  return awaitWrap(
    request({
      url: `/user/official/page`,
      method: 'get',
      params,
      isLoading: true
    })
  )
}
/**
 * 用户余额列表
 * @param {*} "pageNo" 页码
 * @param {*} "pageSize" 页数
 * @param {*} "isPhoneAuth" 手机号类型
 * @param {*} "keyWords" 搜索关键字
 * @param {*} "startTime" 开始时间
 * @param {*} "endTime" 结束时间
 * @returns
 */
export const userWalletList = ({ ...params }) => {
  return awaitWrap(
    request({
      url: `/user/wallet/page`,
      method: 'get',
      params,
      isLoading: true
    })
  )
}
/**
 * 金牌收益列表
 * @param {*} "pageNo" 页码
 * @param {*} "pageSize" 页数
 * @param {*} "keyWords" 搜索关键字
 * @param {*} "startTime" 开始时间
 * @param {*} "endTime" 结束时间
 * @returns
 */
export const userGoldenWalletList = ({ ...params }) => {
  return awaitWrap(
    request({
      url: `/user/golden/wallet/page`,
      method: 'get',
      params,
      isLoading: true
    })
  )
}
/**
 * 搜索用户
 * @param {*} "keyWords" 搜索关键字
 * @returns
 */
export const userSearch = ({ ...params }) => {
  return awaitWrap(
    request({
      url: `/user/search`,
      method: 'get',
      params,
      isLoading: true
    })
  )
}
/**
 * 添加官方推荐官
 * @param {*} "userId" 
 * @returns
 */
export const userOfficialAdd = ({ ...data }) => {
  return awaitWrap(
    request({
      url: `/user/official/add`,
      method: 'post',
      data,
      isLoading: true
    })
  )
}
